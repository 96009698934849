import { Dispatch, SetStateAction } from 'react';
import { defineMessages, IntlShape } from 'react-intl';
import {
  ErrorResponse,
  ValidationErrorResponse,
} from '@models/http/error-response';

interface HandleChangeParams<T> {
  values: T;
  setValues: Dispatch<SetStateAction<T>>;
  getExtras?: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => Partial<T>;
}

export const handleFormChange =
  <T>({ setValues, values, getExtras }: HandleChangeParams<T>) =>
  (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value, type } = e.target;
    const files = (e.target as HTMLInputElement).files;
    const extras = getExtras ? getExtras(e) : {};

    const _values = {
      ...values,
      ...extras,
      [name]:
        type === 'checkbox'
          ? (e.target as HTMLInputElement).checked
          : type === 'file'
          ? files
          : value,
    };
    setValues(_values);
    return _values;
  };

export const EMAIL_VALIDATION = /([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})/i;
export const MIN_PASSWORD_LENGTH = 8;

type FormErrorType =
  | 'required'
  | 'maxLength'
  | 'minLength'
  | 'custom'
  | 'passwordStrength'
  | 'confirmation'
  | 'video_url'
  | 'privacyPolicy'
  | 'vimeo_url';

export interface FormError {
  type: FormErrorType;
  message?: string;
  minMaxNumber?: number;
  intlValues?: any;
}

export type FormErrors<T> = {
  [Property in keyof T]?: FormError;
};

export const validateRequiredFields = <T>(
  requiredFields: (keyof T)[],
  values: T
) => {
  const errors: FormErrors<T> = {};
  for (const field of requiredFields) {
    if (!values[field] || !(values[field] as any).length) {
      errors[field] = {
        type: 'required',
      };
    }
  }
  return errors;
};

// Ref: https://stackoverflow.com/a/5831191/168581
export const YOUTUBE_REGEX =
  /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*?[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/i;

// Ref: https://stackoverflow.com/a/16841070/168581
export const VIMEO_REGEX =
  /(?:https?:\/\/)?(?:www.)?(?:player.)?vimeo.com\/(?:[a-z]*\/)*([0-9]{6,11})[?]?.*/i;

export const validateVideoEmbed = (url?: string): FormError | undefined => {
  if (url) {
    if (YOUTUBE_REGEX.exec(url) || VIMEO_REGEX.exec(url)) {
      return;
    }
    return {
      type: 'video_url',
    };
  }
};

export const validateVimeoEmbed = (url?: string): FormError | undefined => {
  if (url) {
    if (VIMEO_REGEX.exec(url)) {
      return;
    }
    return {
      type: 'vimeo_url',
    };
  }
};

export const validateEmail = (
  email?: string,
  errorMessage?: string
): FormError | undefined => {
  if (email && email.length && !EMAIL_VALIDATION.test(email)) {
    return {
      type: 'custom',
      message: errorMessage
        ? errorMessage
        : 'Please enter a valid email address.',
    };
  }
};

export const validateFieldConfirmation = (
  field1?: string,
  field2?: string
): FormError | undefined => {
  if (field1 && field1.length && field2 && field2.length && field1 !== field2) {
    return {
      type: 'confirmation',
    };
  }
};

export const validatePassword = (password: string): FormError | undefined => {
  if (password && password.length && password.length < MIN_PASSWORD_LENGTH) {
    return {
      type: 'minLength',
      minMaxNumber: MIN_PASSWORD_LENGTH,
    };
  }
  if (password && password.length) {
    const score = checkPasswordStrength(password);
    if (score < 3) {
      return {
        type: 'passwordStrength',
      };
    }
  }
};

interface ValidateMinMaxLengthParams {
  value: string;
  min?: number;
  max?: number;
}
export const validateMinMaxLength = ({
  value,
  min,
  max,
}: ValidateMinMaxLengthParams): FormError | undefined => {
  if (min && value && value.length < min) {
    return {
      type: 'minLength',
      minMaxNumber: min,
    };
  }
  if (max && value && value.length > max) {
    return {
      type: 'maxLength',
      minMaxNumber: max,
    };
  }
};

export const checkPasswordStrength = (password: string) => {
  let score = 0;
  if (/[A-Z]/.test(password)) {
    score++;
  }
  if (/\d/.test(password)) {
    score++;
  }
  if (/\W/.test(password)) {
    score++;
  }
  if (/[a-z]/.test(password)) {
    score++;
  }
  return score;
};

const errorMessages = defineMessages({
  requiredWithLabel: {
    id: 'rifuBE',
    defaultMessage: 'Please enter your {label}',
    default: 'Error message for form field',
  },
  required: {
    id: 'TKmub+',
    defaultMessage: 'This field is required',
    default: 'Error message for form field',
  },
  maxLengthWithLabel: {
    id: 'G0H+zm',
    defaultMessage: '{label} is too long (maximum is {max} characters).',
    default: 'Error message for form field',
  },
  maxLength: {
    id: 'IU698f',
    defaultMessage: 'This field can only be {max} characters.',
    default: 'Error message for form field',
  },
  minLengthWithLabel: {
    id: 'Pq4I4e',
    defaultMessage: '{label} is too short (minimum is {min} characters).',
    default: 'Error message for form field',
  },
  minLength: {
    id: 'EYmMBv',
    defaultMessage: 'This field must be at least {min} characters.',
    default: 'Error message for form field',
  },
  confirmation: {
    id: '/jrI+P',
    defaultMessage: "These fields don't match. Please try again.",
    default: 'Error message for form field',
  },
  passwordStrength: {
    id: 'rZFI/k',
    defaultMessage:
      'Password must meet 3 of the following: number, lower case character, upper case character, non-alphanumeric character.',
    default: 'Error message for form field',
  },
  videoUrl: {
    id: 'GJ9AQv',
    defaultMessage: 'Url must be a valid Vimeo or Youtube url.',
    default: 'Error message for form field',
  },
  vimeoUrl: {
    id: 'E0eeAl',
    defaultMessage: 'Url must be a valid Vimeo url.',
    default: 'Error message for form field',
  },
  videoUrlWithLabel: {
    id: 'UWlAln',
    defaultMessage: '{label} must be a valid Vimeo or Youtube url.',
    default: 'Error message for form field',
  },
  vimeoUrlWithLabel: {
    id: '1rOGVB',
    defaultMessage: '{label} must be a valid Vimeo url.',
    default: 'Error message for form field',
  },
  generic: {
    id: 'WQRh/s',
    defaultMessage: 'This field has an error.',
    default: 'Error message for form field',
  },
});

export const getErrorMessage = (
  intl: IntlShape,
  error: FormError,
  label?: string
) => {
  if (error.type === 'required') {
    if (label) {
      return intl.formatMessage(errorMessages.requiredWithLabel, {
        label: label.toLowerCase(),
      });
    }
    return intl.formatMessage(errorMessages.required);
  }
  if (error.type === 'custom' && error.message) {
    return error.message;
  }
  if (error.type === 'maxLength') {
    if (label) {
      return intl.formatMessage(errorMessages.maxLengthWithLabel, {
        label,
        max: error.minMaxNumber,
      });
    }
    return intl.formatMessage(errorMessages.maxLength, {
      max: error.minMaxNumber,
    });
  }
  if (error.type === 'minLength') {
    if (label) {
      return intl.formatMessage(errorMessages.minLengthWithLabel, {
        label,
        min: error.minMaxNumber,
      });
    }
    return intl.formatMessage(errorMessages.minLength, {
      min: error.minMaxNumber,
    });
  }
  if (error.type === 'confirmation') {
    return intl.formatMessage(errorMessages.confirmation);
  }
  if (error.type === 'passwordStrength') {
    return intl.formatMessage(errorMessages.passwordStrength);
  }
  if (error.type === 'video_url') {
    if (label) {
      return intl.formatMessage(errorMessages.videoUrlWithLabel, { label });
    }
    return intl.formatMessage(errorMessages.videoUrl);
  }

  if (error.type === 'vimeo_url') {
    if (label) {
      return intl.formatMessage(errorMessages.vimeoUrlWithLabel, { label });
    }
    return intl.formatMessage(errorMessages.vimeoUrl);
  }

  return intl.formatMessage(errorMessages.generic);
};

interface SetErrorMessageParams<T> {
  response: Response;
  setSubmitError: (data: string[]) => void;
  setErrors: Dispatch<SetStateAction<FormErrors<T> | undefined>>;
  intl: IntlShape;
}

export const setResponseErrorMessages = async <T>({
  response,
  setErrors,
  setSubmitError,
  intl,
}: SetErrorMessageParams<T>) => {
  if (response.status === 422) {
    const responseData: ValidationErrorResponse = await response.json();
    const errors: FormErrors<T> = {};
    if (!responseData.errors) {
      setSubmitError([
        responseData.detail || getIntlErrorMessage('processingError', intl),
      ]);
      return;
    }

    responseData.errors.forEach((error) => {
      errors[error.field as keyof T] = {
        type: 'custom',
        message: `${error.human_field} ${
          error.detail_localized || error.title
        }.`,
      };
    });
    setErrors(errors);
    setSubmitError(
      responseData.errors.map(
        (error) =>
          `${error.human_field} ${error.detail_localized || error.title}.`
      )
    );
  } else {
    try {
      const responseData: ErrorResponse = await response.json();
      if (responseData.recaptcha_failed) {
        setSubmitError([getIntlErrorMessage('recaptchaFailed', intl)]);
      } else if (responseData.detail_localized) {
        setSubmitError([responseData.detail_localized]);
      } else {
        setSubmitError([
          responseData.detail || getIntlErrorMessage('processingError', intl),
        ]);
      }
    } catch (err) {
      setSubmitError([getIntlErrorMessage('processingError', intl)]);
    }
  }
};

export const getElementError =
  <T>(errors: FormErrors<T | undefined>, eventTrigger?: boolean) =>
  (field: keyof T) => {
    if (eventTrigger === undefined) {
      eventTrigger = true;
    }
    if (errors && eventTrigger) {
      return errors[field];
    }
    return undefined;
  };

const messages = defineMessages({
  formValidationErrors: {
    id: 'I2lI48',
    defaultMessage: 'Please correct the fields above in red and try again.',
    description:
      'Error message shown to users when a form has validation errors.',
  },
  processingError: {
    id: 'dNXMU0',
    defaultMessage:
      'There was an error processing your request. Please try again.',
    description:
      'Error shown to user when there was a general error such as a network failure.',
  },
  loginFailed: {
    id: 'AshHia',
    defaultMessage: 'Error logging in. Please try again.',
    description: 'Error shown when a login combination failed.',
  },
  recaptchaNotLoaded: {
    id: 'f7g0vS',
    defaultMessage: 'Recaptcha has not loaded, please try again.',
    description:
      'Error shown to user when the recaptcha service has not loaded yet.',
  },
  recaptchaFailed: {
    id: 'Lm6OuX',
    defaultMessage: 'Failed recaptcha, please try again.',
    description: 'Error shown to user when they failed recaptcha.',
  },
  unauthenticated: {
    id: 'OIIXKV',
    defaultMessage: 'Please login.',
    description:
      'Error shown to a user when they try to make a submission on a form that requires them to be logged in. This only happens if they stay on a page so long that their session ends or they logout on another tab.',
  },
  capturePaymentError: {
    id: 'a2YJXx',
    defaultMessage: 'There was an error submitting your confirmation.',
    description:
      'Error shown when the final step in capturing a payment is unsuccessful.',
  },
  paymentAPIError: {
    id: 'QMuEyX',
    defaultMessage:
      'Something went wrong processing your request. Please go back to the previous page, or refresh your browser.',
    description:
      'Error shown to a user on one of the payment pages if an unexpected API error happens.',
  },
  recurringNeedPaymentMethodError: {
    id: 'FjCSve',
    defaultMessage:
      'You must have a saved payment method to make a recurring donation. Please go back to the previous page.',
    description:
      'Error shown when a user tries to make a recurringn donation without having a saved payment method.',
  },
  recurringIntervalError: {
    id: 'R+VJDZ',
    defaultMessage:
      'You must select an interval for a recurring donation. Please go back to the previous page.',
    description:
      'Error shown when a user needs to select an interval for a recurring donation.',
  },
});
export const getIntlErrorMessage = (
  key: keyof typeof messages,
  intl: IntlShape
) => {
  return intl.formatMessage(messages[key]);
};
